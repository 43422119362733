<template>
  <div>
    <SpFinder
      id="44"
      ref="finder"
      :url="{
        setting: schemaSetting,
        data: roleList
      }"
      :actions="actions"
      :columns="columns"
      :data="[]"
      title="角色管理"
    />
    <RoleDrawer
      ref="roleDrawer"
      :visible="visible"
      :role-id="rowRoleId"
      @refresh="() => $refs.finder.refresh()"
      @close="visible = false"
    />
  </div>
</template>

<script>
import { deleteRole } from '@/api/role';
import RoleDrawer from './component/role_drawer';
import { schemaSetting, roleList } from '@/api/finder';

export default {
  components: { RoleDrawer },
  data () {
    return {
      schemaSetting,
      roleList,
      visible: false,
      rowRoleId: '',
      columns: [{ name: '已关联账号数量', key: 'count', block: 'main' }],
      actions: [
        {
          name: '创建角色',
          key: 'create',
          slot: 'header',
          type: 'button',
          buttonType: 'primary',
          action: {
            type: null,
            handler: () => {
              this.visible = true;
              this.rowRoleId = '';
              this.$refs.roleDrawer.setMenuList();
            },
          },
        },
        {
          key: 'edit',
          name: '编辑',
          type: 'button',
          block: 'footer',
          visible: (row) => !(row.superAdmin),
          action: {
            type: null,
            handler: (row) => {
              this.visible = true;
              this.rowRoleId = row[0].id;
              const params = {
                id: this.rowRoleId,
                pageNum: 1,
                pageSize: 9999,
              };
              this.$refs.roleDrawer.getRoleDetail(params);
            },
          },
        },
        {
          key: 'delete',
          name: '删除',
          type: 'button',
          block: 'header',
          visible: (row) => !(row.superAdmin),
          action: {
            type: null,
            handler: (rows) => {
              this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              }).then(() => {
                for (let i = 0; i < rows.length; i++) {
                  this.deleteRole(rows[i].id, i);
                }
              });
            },
          },
        },
      ],
    };
  },
  methods: {
    async deleteRole (id, index) {
      await deleteRole(id);
      if (index === 0) {
        this.$message.success('删除成功');
      }
      this.$refs.finder.refresh(true);
    },
  },
};
</script>
