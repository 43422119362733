<template>
  <el-drawer :visible="visible" :size="'50%'" custom-class="role-create-drawer" @close="reset">
    <template #title>
      <h3 class="role-create-drawer-title">
        {{ roleId ? '编辑角色' : '新增角色' }}
      </h3>
    </template>
    <div v-loading="loading" class="role-create">
      <el-form label-position="right" label-width="80px">
        <el-form-item label="角色名称" required>
          <el-input v-model="formModel.name" placeholder="请输入角色名称" size="small" show-word-limit />
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input v-model="formModel.description" :rows="4" placeholder="请输入角色描述" size="small" type="textarea" show-word-limit />
        </el-form-item>
      </el-form>
      <div v-if="menuList.length" class="rule-setting">
        <p>
          <span>权限设置</span>
        </p>
        <PermissionSettingApp ref="permissionSetting" :menu-list="menuList" />
        <div class="submit-btn">
          <el-button size="small" @click="reset">
            取消
          </el-button>
          <el-button type="primary" size="small" @click="submit">
            保存
          </el-button>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createRole, updateRole } from '@/api/role';
import PermissionSettingApp from '@/components/permission/permission-setApp';

export default {
  name: 'RoleDrawer',
  components: {
    PermissionSettingApp,
  },
  props: {
    roleId: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      rangeList: [],
      menuList: [],
      menuActive: -1,
      formModel: {
        name: '',
        description: '',
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      permissionList: (state) => state.role.permissionList,
      roleDetail: (state) => state.role.roleDetail,
    }),
  },
  watch: {
    roleDetail: {
      deep: true,
      handler () {
        let menuList = JSON.parse(JSON.stringify(this.permissionList));
        const { name, description, rangeList } = this.roleDetail;
        this.formModel = { name, description };
        if (rangeList) {
          // app级别勾选
          menuList.forEach(item => {
            rangeList.forEach(app => {
              if (item.id === app.appId) {
                this.$set(item, 'rangeType', app.rangeType);
              }
            });
          });
          // 子级勾选
          let ids = [];
          rangeList.forEach(item => {
            if (item.permissionIds) {
              ids = ids.concat(item.permissionIds);
            }
          });
          this.setChecked(menuList, ids);
        }
        this.menuList = menuList;
      },
      immediate: true,
    },
  },
  mounted () {
    this.loading = true;
    this.getPermissionList().finally(() => (this.loading = false));
  },
  methods: {
    ...mapActions({
      getPermissionList: 'role/getPermissionList',
      getRoleDetail: 'role/getRoleDetail',
    }),
    onAppContentScroll (e) {
      const top = e.target.scrollTop;
      const el = document.querySelector('#menuNav');
      const className = 'menu-nav-fixed';
      if (top >= 300) {
        if (!el.classList.contains(className)) el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    setMenuList () {
      this.getPermissionList();
      this.menuList = JSON.parse(JSON.stringify(this.permissionList));
      this.setChecked(this.menuList, []);
    },
    reset () {
      this.formModel.name = '';
      this.formModel.description = '';
      this.$emit('close');
    },
    /**
     * 创建/修改 角色
     */
    async submit () {
      const { name, description } = this.formModel;
      const isUpdate = !!this.roleId;
      const rangeList = this.$refs.permissionSetting.getChecked();
      if (name.length === 0) {
        return this.$message.error('名称不能为空');
      }
      let res = {};
      if (isUpdate) {
        res = await updateRole({ name, description, rangeList, id: this.roleId });
      } else {
        res = await createRole({ name, description, rangeList });
      }
      this.$message.success(isUpdate ? '修改成功' : '创建成功');
      this.$emit('refresh');
      this.reset();
    },
    setMenuActive (item) {
      this.menuActive = item.id;
      location.replace(`#${item.id}`);
    },
    // 设置子级勾选
    setChecked (list, ids) {
      list.forEach(item => {
        if (item.children && item.children.length) {
          this.setChecked(item.children, ids);
        } else {
          const checked = ids.includes(item.id);
          this.$set(item, 'checked', checked);
        }
      });
    },
  },
};
</script>
<style lang="scss">
.role-create-drawer {
  overflow: auto;
  .el-drawer__header {
    padding-top: 0;
    color: #222222;
    border-bottom: 1px solid #e9e9e9;
  }
}
</style>
<style lang="scss" scoped>
.role-create {
  min-height: 100%;
  height: auto;
  background: #ffffff;
  box-sizing: border-box;
  padding: 20px;

  .menu-nav-fixed {
    position: fixed;
    top: 48px;
    left: 240px;
    width: 100%;
    background: #fff;
    z-index: 100;
  }
  .rule-setting {
    > p {
      width: 80px;
      text-align: right;
      box-sizing: border-box;
      padding-right: 12px;
      font-size: 14px;

      :first-child {
        color: #666666;
      }

      :first-child::before {
        content: '*';
        color: #f56c6c;
        margin-right: 4px;
      }
    }

    .data-rule {
      border-left: 4px solid #1890ff;
      padding-left: 8px;
      font-size: 16px;
      color: #666666;
      background: #ffffff;
      line-height: 40px;
      margin: 0 0 10px 0;
    }

    .menu-rule {
      font-size: 16px;
      color: #666666;
      margin-right: 12px;
      box-sizing: border-box;
      cursor: pointer;

      a {
        text-decoration: none;
        color: #666666;
      }
    }

    .menu-rule-active {
      border-bottom: 2px solid #1890ff;
      color: #1890ff;

      a {
        color: #1890ff;
      }
    }

    .rule-item {
      background: #f1f2f3;
      box-sizing: border-box;
      padding: 10px;
      margin-top: 12px;
    }

    .submit-btn {
      background: #ffffff;
      box-sizing: border-box;
      padding: 20px 20px 20px 10px;
    }
  }
}
</style>
